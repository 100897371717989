<script setup>

    // ProfilePanel
    // Shows the profile panel of the my account area.

    // TODO: Add a (universally-located) scroll so that adding a new shipping address will bring the form into view.

    // Components ----
    import ShippingAddressTile from '@/site/components/ShippingAddressTile';

    // Imports ----
    import { ref } from 'vue';
    import { store, DEFAULT_COUNTRY_CODE } from '@/Store';
    import { Search, ShippingAddressForm } from '@dd-nucleus/nucleus-vue';
    import UpdateAccountInfoForm from '../forms/UpdateAccountInfoForm';

    // State ----
    const selectedCountry = ref(DEFAULT_COUNTRY_CODE);
    const defaultCountry = ref(DEFAULT_COUNTRY_CODE);
    const search = new Search('addresses-for-user', 'tile', 'address-sequence', 1000);
    search.setEmptyFilter('address-not-default');

    const editingAccount = ref(false);
    const addingShippingAddress = ref(false);

    let accountInfoForm = new UpdateAccountInfoForm();
    let addShippingAddressForm = new ShippingAddressForm();

    // Handlers ----
    function onEditAccount() {
        accountInfoForm.init();
        editingAccount.value = true;
    }

    function onCancelEditAccount() {
        editingAccount.value = false;
    }

    async function onSaveAccount() {
        await accountInfoForm.submit();
        editingAccount.value = false;
    }

    function onAddShippingAddress() {
        addShippingAddressForm.model.countryCode = defaultCountry.value;
        addingShippingAddress.value = true;
    }

    function onCancelAddShippingAddress() {
        addShippingAddressForm.reset();
        addingShippingAddress.value = false;
    }

    async function onSaveShippingAddress() {
        const succeeded = await addShippingAddressForm.submit();
        if (succeeded) {
            addingShippingAddress.value = false;
            addShippingAddressForm.reset();
        } else {
            addShippingAddressForm.validate();
        }

        await store.refreshSearches('addresses-for-user');
    }

    function onChanged(form) {
        if (selectedCountry.value != form.model.countryCode) {
            selectedCountry.value = form.model.countryCode;
            form.model.state = '';
            form.model.province = '';
            form.model.addressLine1 = '';
            form.model.addressLine2 = '';
            form.model.city = '';
            form.model.postalCode = '';
        }
    }


</script>

<template>
    <div class="account-panel">
        <h1 class="cart-page-header mb-3">My Account <span class="text-muted">/ My Profile</span></h1>

        <BodyPanel title="Account Information">
            <FormContainer :form="accountInfoForm">
                <div class="row g-2 mb-4">
                    <div class="col-2">Name:</div>
                    <div class="col-10"><b>{{store.user.firstName}} {{ store.user.lastName}}</b></div>

                    <div class="col-2 d-flex align-items-center">Username:</div>
                    <div v-if="editingAccount" class="col-10">
                        <TextBoxField v-model="accountInfoForm.model.userName" field-name="userName" />
                    </div>
                    <div v-else class="col-10">{{store.user.userName}}</div>

                    <div class="col-2 d-flex align-items-center">Email:</div>
                    <div v-if="editingAccount" class="col-10">
                        <TextBoxField v-model="accountInfoForm.model.email" field-name="email" />
                    </div>
                    <div v-else class="col-10">{{store.user.email}}</div>

                    <div v-if="editingAccount" class="col-12 pt-2">
                        <button class="btn btn-outline-color-2 me-2" @click="onCancelEditAccount">Cancel</button>
                        <button class="btn btn-color-2" @click="onSaveAccount">Save</button>
                    </div>
                    <div v-else class="col-12 pt-2"><ActionButton v-if="false" icon="bi bi-pencil-fill" label="Edit" @click="onEditAccount" /></div>
                </div>
            </FormContainer>
        </BodyPanel>

        <BodyPanel title="My Addresses">
            <div class="row g-2 mb-2">
                <div class="col-6 section-subtitle"><strong>Default Shipping Address</strong></div>
                <div class="col-6 section-subtitle"><strong>Account Address</strong></div>
            </div>
            <div class="row g-2">
                <div class="col-6"><ShippingAddressTile deletable editable :address="store.shipping.addresses[0]" :border="false" /></div>
                <div class="col-6"><ShippingAddressTile :address="store.account.address" :border="false" /></div>
            </div>

            <div class="row mt-4">
                <div class="mb-2 section-subtitle"><strong>Other Shipping Addresses</strong></div>

                <div v-if="addingShippingAddress" class="subform mt-2" ref="shippingAddressForm">
                    <div class="col-12 col-md-8">
                        <div class="header">New Shipping Address</div>
                        <EditShippingAddress :form="addShippingAddressForm" :address-only="true" :start-address-lines="2" :max-address-lines="2" :selectedCountry="selectedCountry" @change="onChanged">
                            <div class="row">
                                <div class="col-12 pt-2">
                                    <button class="btn btn-outline-color-2 me-2" @click="onCancelAddShippingAddress">Cancel</button>
                                    <button class="btn btn-color-2" @click="onSaveShippingAddress" :disabled="addShippingAddressForm.status.isInvalid || addShippingAddressForm.status.isSaving">Save</button>
                                </div>
                            </div>
                        </EditShippingAddress>
                    </div>
                </div>

                <div v-else>
                    <SearchContainer :search="search" :deep-link="false">

                        <SearchGrid>
                            <!-- Template for each item in a grid view -->
                            <template v-slot:grid="address">

                                <ShippingAddressTile deletable editable :address="address" :can-set-default="true">
                                </ShippingAddressTile>

                            </template>
                        </SearchGrid>

                    </SearchContainer>

                    <div class="mt-1">
                        <ActionButton icon="bi bi-plus-lg" label="Add Shipping Address" @click="onAddShippingAddress" />
                    </div>
                </div>
            </div>


        </BodyPanel>

    </div>
</template>

<style lang="scss">

    .section-subtitle {
        border-bottom: 1px solid $gray-10;
    }
</style>