<script setup>

    // SignUpAccountTypePage
    // Page that allows a user coming through generic sign-in to choose the type of account.
    // TODO: Something wonky is going on with vertically spacing the radio button list
    // TODO: Responsiveness related to the above

    // Components ----
    import SignInPageTemplate from '@/template/SignInPageTemplate';

    // Imports ----
    import { reactive } from 'vue';
    import { useRouter } from 'vue-router'
    import AccountTypeForm from './forms/AccountTypeForm';

    const router = useRouter();

    // State ----
    const form = reactive(new AccountTypeForm());
    const options = [
        { id: 'auto', text: 'I am an Automotive User' },
        { id: 'retail', text: 'I am a Retailer' }
    ];

    // Handlers ----
    function onNext() {
        if (form.model.accountType === 'auto')
            router.push('/auto');
        else
            router.push('/retail');
    }
</script>

<template>
    <SignInPageTemplate>
        <div class="container page-container">

            <div class="row">
                <div class="col"></div>
                <div class="col col-12 col-sm-10 col-lg-6 col-xl-5">
                    <div class="create-account-header mt-5 mb-4">
                        <h1 class="text-center">Create Account</h1>
                        <div class="text-left">To help us better align you with the most relevant products based on your needs, please select one of the following options: </div>
                    </div>

                    <FormContainer :form="form">

                        <div class="row mb-3">
                            <div class="col col-6 offset-3">
                                <RadioButtonListField v-model="form.model.accountType" field-name="accountType" :options="options" />
                            </div>
                        </div>
                        <div class="row mt-4 mb-5">
                            <div class="col col-6 d-grid">
                                <router-link to="/sign-in" class="btn btn-outline-color-1">Cancel</router-link>
                            </div>
                            <div class="col col-6 d-grid">
                                <button class="btn btn-color-1" @click="onNext" :disabled="form.status.isInvalid">NEXT</button>
                            </div>
                        </div>

                    </FormContainer>

                </div>
                <div class="col"></div>
            </div>

        </div>
    </SignInPageTemplate>
</template>

<style lang="scss">

    .page-container {
        margin-bottom: 8rem;
    }

    .create-account-header h1 {
        color: $black;
        font-weight: $normal;
    }

</style>