// Services related to address list

export default class AddressList {

    // Constructor
    constructor(store) {
        this.store = store;
    }
    /**
     * upload excel file
     * @param {file} payload
     */
    async uploadAddressList(file) {
        return await this.store.api.put(`/api/store/cart/ship-to-contacts/upload`, file);
    }

    /**
     * upload excel file
     * @param {file} payload
     */
    async validateAddresses(addresses) {
        return await this.store.api.post(`/api/store/cart/ship-to-contacts/validate`, addresses);
    }

}
