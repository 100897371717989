<script setup>
    //ImpersonateAlert

    // Components

    // Imports
    import { computed } from 'vue';
    import { useRouter } from 'vue-router';
    import { store } from '@/Store';

    // State
    const router = useRouter();

    // Computed
    const isImpersonating = computed(() => {
        return store.user.isImpersonating;
    })

    const name = computed(() => {
        return store.user.firstName + " "+ store.user.lastName;
    })

    // Handlers
    function endEmulation() {
        const endImpersonateSession = confirm("Are you sure you want to stop the emulation session this user?");
        if (endImpersonateSession) {
            store.authenticator.deleteImpersonateSession();
            router.push({ name: 'ImpersonationEnded' });
        }
    }

</script>


<template>
    <div class="emulationContainer position-relative" v-if="isImpersonating">
        <div class="container alert alert-warning sticky-top mt-3">
            <div class="d-flex emulation">
                <div>
                    <i class="bi bi-people-fill fs-2" />
                </div>
                <div>
                    <p class="mb-0">
                        You are in an Emulation Session for
                        <span class="fw-bold">{{name}}</span>
                    </p>
                </div>
                <div>
                    <button class="btn btn-primary btn-sm" @click="endEmulation">End Emulation</button>
                </div>
            </div>
        </div>
    </div>
</template>


<style lang='scss'>
    .emulationContainer{
        z-index:1;

        .emulation {
            align-items: center;
            gap: 1.5rem;
        }
    }
</style>