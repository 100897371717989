export const UserRoles = [
    {
        "id": "Qualfon.Csd",
        "name": "Qualfon CSD",
    },
    {
        "id": "Qualfon.CsdAdmin",
        "name": "Qualfon CSD Admin",
    },
    {
        "id": "Qualfon.Support",
        "name": "Qualfon Support",
    },
    {
        "id": "Client.Admin",
        "name": "Otsuka Admin",
    },
    {
        "id": "SA",
        "name": "System Administrator",
    }
];

// Admin roles
export const UserRolesWithAdminAccess = [
    {
        "id": "Qualfon.Csd",
        "name": "Qualfon CSD",
    },
    {
        "id": "Qualfon.CsdAdmin",
        "name": "Qualfon CSD Admin",
    },
    {
        "id": "Client.Admin",
        "name": "Otsuka Admin",
    },
    {
        "id": "SA",
        "name": "System Administrator",
    }
];

// division
export const Divisions = [
    { id: 'Automotive', text: 'Automotive' },
    { id: 'OEM', text: 'OEM' },
    { id: 'Retail', text: 'Retail' }
];

// user statuses
export const UserStatuses = [
    { id: 'Active', text: 'Active' },
    { id: 'Inactive', text: 'Inactive' },
    { id: 'On Leave', text: 'On Leave' }
];

// TODO: This needs to be fetched from API
export const LimitType = {
    none: 0,
    monthly: 3 
}