<script>

    // OrderItemList
    // A list of order items, typically used when showing order detail.
    // TODO: Move this to a more appropriate folder

    // Components ----
    import OrderItemTile from './OrderItemTile';

</script>

<script setup>

    // Imports ----
    import { defineProps } from 'vue';

    // Props ----
    const props = defineProps({
        // Zero-based index of the cart item
        order: {
            type: Object,
            required: true
        }
    });

    // Methods ----
    function getCssClass(index) {
        return { shaded: index % 2 > 0 };
    }

    // Handlers ----
</script>

<template>

    <div class="order-item-list">
        <template v-if="props.order.items.length > 0">
            <div v-for="(item, index) in props.order.items" :key="item.id" class="order-item-container" :class="getCssClass(index)">
                <OrderItemTile :item="item" />
            </div>
        </template>
        <div v-else>
            <slot name="empty"></slot>
        </div>
    </div>

</template>

<style lang="scss">

    .order-item-list {
        .order-item-container {
            margin-bottom: 0.5rem;
            padding: 0.5rem;
        }

        .order-item-tile:last-child .inner-row {
            border-bottom: 0px;
        }
    }

</style>