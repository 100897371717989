<script setup>

    // SignUpForm
    // Provides a generic form to create a new account.

    // Components ----
    import { PasswordHints, MessageType } from '@dd-nucleus/nucleus-vue';

    // Imports ----
    import { computed, reactive, ref } from 'vue';
    import SignUpAutoForm from '../forms/SignUpAutoForm';
    import { useRouter } from 'vue-router';
    import { store } from '@/Store';

    const router = useRouter();

    // State ----
    const form = reactive(new SignUpAutoForm());
    const isValidatingPassword = ref(false);
    // Handlers ----
    async function onSignUp() {
        const succeeded = await form.submit();

        // Route to the sign-in page on success, otherwise stay on this page
        // and the form will display any error messages.
        if (succeeded) {
            router.push({ path: '/sign-in', query: { created: 'true' } });
        }
        else {
            form.scrollTop();
        }
    }

    // Computed ----
    const allPasswordConditionsMet = computed(() => {
        return form.validator.fields.password.messages.every(m => m.type === MessageType.OK);
    });

    const showPasswordRequirements = computed(() => {
        if (allPasswordConditionsMet.value) {
            return false;
        }

        return (isValidatingPassword.value ) && (form.model.password.length >= 0);
    });

    function onPasswordTextChange() {
        // TODO: Is there a debounce utility available?
        isValidatingPassword.value = true;
    }
    
</script>

<template>
    <FormContainer :form="form" optional-text="(Optional)">

         <ValidatorMessages :validator="form.validator" class="mb-2" v-if="!showPasswordRequirements" /> 

        <div class="mb-3">
            <FieldLabel field-name="email" label="Email">
                <TextBoxField v-model="form.model.email" placeholder="Email Address" />
                <FieldMessages />
            </FieldLabel>
        </div>
        <div class="row">
            <div class="col-md-12 col-lg-12">
                <div class="mb-3">
                    <FieldLabel field-name="password" label="Password">
                        <TextBoxField v-model="form.model.password" placeholder="Password" :is-password="true" @keyup="onPasswordTextChange"  show-pwd-btn-class="btn-color-2-xlt" />
                        <PasswordHints />
                    </FieldLabel>
                </div>
                <div class="mb-3">
                    <FieldLabel field-name="confirmPassword" label="Confirm Password">
                        <TextBoxField v-model="form.model.confirmPassword" placeholder="Password" :is-password="true" show-pwd-btn-class="btn-color-2-xlt" />
                        
                    </FieldLabel>
                </div>
            </div>
            <div class="col-md-5 col-lg-4">
                <div class="error-label-password"  v-if="showPasswordRequirements">
                    <div class="password-requirements-box">
                                <b>Password Requirements</b>
                                <FieldMessages field-name="password" :add-box="false" />
                    </div>
                </div>
            </div>
        </div>
        <div class="mb-3">
            <FieldLabel field-name="companyName" label="Company Name">
                <TextBoxField v-model="form.model.companyName" placeholder="Company Name" />
            </FieldLabel>
        </div>
        <div class="mb-3">
            <FieldLabel field-name="firstName" label="First Name">
                <TextBoxField v-model="form.model.firstName" placeholder="First Name" />
            </FieldLabel>
        </div>
        <div class="mb-3">
            <FieldLabel field-name="lastName" label="Last Name">
                <TextBoxField v-model="form.model.lastName" placeholder="Last Name" />
            </FieldLabel>
        </div>
        <div class="mb-3">
            <FieldLabel field-name="automotiveType" label="Automotive Type">
                <DropDownListField v-model="form.model.automotiveType" :options="form.automotiveTypes" null-option-text="Select Automotive Type" />
            </FieldLabel>
        </div>
        <div class="mb-3">
            <FieldLabel field-name="phoneNumber" label="Phone Number">
                <TextBoxField v-model="form.model.phoneNumber" placeholder="Phone Number" :is-phone-number="true" />
                <FieldMessages />
            </FieldLabel>
        </div>
        <div class="mb-3">
            <FieldLabel field-name="addressLine1" label="Street Address">
                <TextBoxField v-model="form.model.addressLine1" placeholder="Street Address" />
                <FieldMessages />
            </FieldLabel>
        </div>
        <div class="mb-3">
            <FieldLabel field-name="addressLine2" label="Address Line 2">
                <TextBoxField v-model="form.model.addressLine2" />
            </FieldLabel>
        </div>
        <div class="mb-3">
            <FieldLabel field-name="city" label="City">
                <TextBoxField v-model="form.model.city" placeholder="City" />
            </FieldLabel>
        </div>
        <div class="mb-3">
            <FieldLabel field-name="state" label="State">
                <DropDownListField v-model="form.model.state" :options="store.shipping.states" null-option-text="Select State" />
            </FieldLabel>
        </div>
        <div class="mb-3">
            <FieldLabel field-name="postalCode" label="Zip Code">
                <TextBoxField v-model="form.model.postalCode" :is-postal-code="true" placeholder="Zip Code" />
            </FieldLabel>
        </div>
        <div class="mb-3">
            <FieldLabel field-name="title" label="Title">
                <TextBoxField v-model="form.model.title" placeholder="Title" />
            </FieldLabel>
        </div>
        <div class="d-grid mt-5">
            <button class="btn btn-color-1" @click="onSignUp" :disabled="form.status.isInvalid">CREATE ACCOUNT</button>
        </div>
    </FormContainer>
</template>

<style lang="scss">

    .n-text-box-field {
        .label {
            font-weight: $bold;
        }
    }

    .password-requirements-box {
        margin-top: 0.25rem;
        min-height: 100px;
    }

    div.error-label-password {
        position: absolute;
    background: #fff;
    border: 1px solid;
    border-color:#b63f29!important;
    border-radius: 3px;
    padding: 10px;
    width: 200px;
    z-index: 200;
    margin-left: 24rem !important;
    margin-top: -9rem !important;
}
div.error-label-password:before {
    content: '';
    right: 100%;
    top: 18%;
    border: solid transparent;
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border: 7px solid transparent;
    border-right-color: #b63f29;
}

.n-message{
display: flex !important;
}
</style>
