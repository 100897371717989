<script setup>

    // SignInPage
    // Primary sign-in page

    // Components ----
    import { onMounted } from 'vue';
    import SignInPageTemplate from '@/template/SignInPageTemplate';
    import SignInForm from './components/SignInForm';
    import { AspNetAuthenticator } from '@dd-nucleus/nucleus-vue';
    import { store } from '@/Store';

    onMounted(async () => {
        //Set AspNetAuthenticator as site.authenticator
        store.initAuthenticator(new AspNetAuthenticator(store));
    });

</script>

<template>
    <SignInPageTemplate>
        <div class="container page-container">

            <div class="row">
                <div class="col"></div>
                <div class="col col-12 col-sm-8 col-lg-6 col-xl-5">
                    <div class="sign-in-header text-center mt-5 mb-3">
                        <h1>Account Sign In</h1>
                    </div>

                    <SignInForm />

                    <div class="divider mb-3">
                    </div>

                </div>
                <div class="col"></div>
            </div>

        </div>
    </SignInPageTemplate>
</template>

<style lang="scss">

    .page-container {
        margin-bottom: 4rem;
    }

    .sign-in-header h1 {
        color: $black;
        font-weight: $normal;
    }

    .divider
    {
        height: 3rem;
        border-bottom: 1px solid $gray-20;
    }

    .no-account h2
    {
        color: $black;
        font-weight: $normal;
        font-size: 1.2rem;
    }

    .n-text-box-field
    {
        .label
        {
            font-weight: $bold;
        }
    }

    .popper-content
    {
        width: 200px;
    }

</style>