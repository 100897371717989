<script setup>
    import { onMounted } from 'vue';
    import { store } from '@/Store';
    import { AzureAuthenticator } from '@dd-nucleus/nucleus-vue';
    import { msalConfig } from '../../../../services/authentication/azuread/msalconfig.js';
    import { useRouter } from 'vue-router'

    onMounted(async () => {
        var router = useRouter();
        store.initAuthenticator(new AzureAuthenticator(store, 'AzureADAuthenticator', msalConfig, router));
    });

</script>

<template>
    <div class="loading-sso">
        <div class="spinner-border" role="status"></div>
        <p>Please wait a moment, signing in...</p>
    </div>
</template>

<style>

    .loading-sso {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        height: 100vh;
        width: 100vw;
        gap: 15px;
        p {
        font-size: 18px;
        margin-left: 10px;
        }
    }
</style>