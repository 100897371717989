<script setup>

    // ProductDetailPage
    // Shows detail for a product.

    // Components ----
    import ProductDetail from '@/site/components/ProductDetail';

    // Imports ----
    import { useRoute } from 'vue-router';

    const route = useRoute();

    // State ----

</script>

<template>
    <PageTemplate>
        <div class="container root-page">

            <ProductDetail :product-id="route.params.id" />

        </div>
    </PageTemplate>
</template>

<style lang="scss">


</style>