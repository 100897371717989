<script setup>
	// OrderConfirmedPage
	// Shows the user a confirmation that their order has been placed.

	// Components ----
	import CartBodyPanel from './components/CartBodyPanel';
	import OrderShipTo from './components/OrderShipTo';
	import OrderShipToMultiple from './components/OrderShipToMultiple';
	import OrderItemList from './components/OrderItemList';

	// Imports ----
	import { computed, reactive, onMounted } from 'vue';
	import { useRoute } from 'vue-router';
	import { store } from '@/Store';
	import { Util } from '@dd-nucleus/nucleus-vue';

	const route = useRoute();

	// State ----
	const order = reactive({ isLoaded: false, detail: {} });

	// Computed ----
	const shipMethod = computed(() => {
		return order.detail.shipMethod;
	});

    const isExpeditedShipping = computed(() => {
        return shipMethod.value.isRush;
    });

	const shipListCount = computed(() => {
		return order.detail.orders.length;
	});

	// Handlers ----
	onMounted(async () => {
		// If an ID was provided, get that order. If none was provided, use cart's order property, which is set when the
		// order is placed.

		if (Util.isEmpty(route.params.id)) Object.assign(order.detail, store.cart.order);
		else {
			Object.assign(order.detail, await store.orders.getOrder(route.params.id));

			let items = [];
			if (order.detail && order.detail.items) {
				items.push(...order.detail.items);
			}

			for (let item of items) {
				await store.products.getProductLimits(item.product, true);
			}
		}

		update();

		store.cart.resetCart();
	});

	// Called once we know we have an order to work with
	function update() {
		order.isLoaded = true;
	}
</script>

<template>
	<PageTemplate>
		<div class="container root-page review-page" v-if="order.isLoaded">
			<div class="text-end">
				<router-link class="btn btn-outline-color-1 me-2" to="/">BACK TO HOME</router-link>
			</div>

			<div class="row">
				<div class="col">
					<!-- If Multi ship-->
					<CartBodyPanel class="cart-body-panel" v-if="order.detail.isMultiShip">
						<div class="text-center mb-4 order-message">
							<h2>We've received your order</h2>
							<div class="order-number mb-1">Order #{{ order.detail.orders[0].bulkOrderNumber }}</div>
							<!--TODO wait for this to be available-->
							An email confirmation containing order details has been sent to <strong>{{ order.detail.orders[0].user.email }}</strong>
						</div>

						<template #title>
							<h2>
								Shipping Details <span class="fs-3">({{ shipListCount }} recipients)</span>
							</h2>
						</template>

						<OrderShipToMultiple :order="order.detail.orders" />
					</CartBodyPanel>

					<!-- If single ship -->
					<div v-else>
						<div class="text-center mb-4 order-message">
							<h2>We've received your order</h2>
							<div class="order-number mb-1">Order #{{ order.detail.orderNumber }}</div>
							An email confirmation containing order details has been sent to <strong>{{ order.detail.user.email }}</strong>
						</div>
						<CartBodyPanel class="cart-body-panel">
							<template #title>
								<h2>Ship To</h2>
							</template>

							<OrderShipTo :order="order.detail" />
						</CartBodyPanel>
					</div>

					<CartBodyPanel class="cart-body-panel ship-method-panel" v-if="!order.detail.isMultiShip">
						<template #title>
							<h2>Ship Method</h2>
						</template>

						<div v-if="!isExpeditedShipping">
							<ShipMethod :ship-method="shipMethod" />
						</div>
						<div v-else>{{ shipMethod.name }}</div>
					</CartBodyPanel>

						<CartBodyPanel class="cart-body-panel order-items-panel" v-if="!order.detail.isMultiShip">
							<template #title>
								<h2>Items</h2>
							</template>

							<OrderItemList :order="order.detail" />
						</CartBodyPanel>
					</div>
			</div>
		</div>
	</PageTemplate>
</template>

<style lang="scss">
	.root-page {
		min-height: 30rem;
	}

	.review-page {
		.order-message h2 {
			font-weight: $normal;
		}

		.order-number {
			font-size: 1.2rem;
			font-weight: $bold;
		}

		.ship-method .title {
			font-weight: bold;
		}

		.panel-title-action {
			margin-top: -0.75rem;
		}

		.bottom-panel {
		}
	}
</style>
