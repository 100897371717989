<script setup>
	// OrderHistoryPanel
	// Shows order history in the my account area.

	// Components ----
	import OrderHistoryTile from '@/site/components/OrderHistoryTile';
	import MockProductListTile from '@/site/components/MockProductListTile';
	import { computed } from 'vue';
	import { useRoute } from 'vue-router';

	// Imports ----

	// State ----
	const pageSizeOptions = [12, 24, 36, 48, 60];

	const route = useRoute();
	// Filter used to include only first order in bulkOrders in order search

	const includeFirstOrderInBulkOrdersFilter = computed(() => {
		if (!route.query) {
			return {
				filterId: 'include-firstOrder-in-bulkOrders',
				values: ['']
			};
		} else {
			return null;
		}
	});

	const perPageCount = computed(() => route.query['per-pg'] ?? 12);
</script>

<template>
	<div class="account-panel order-history-panel">
		<h1 class="cart-page-header mb-3">My Account <span class="text-muted">/ My Order History</span></h1>

		<SearchContainer
			search-id="orders-for-user"
			sort-id="order-newest-to-oldest"
			default-view="list"
			model-id="tile"
			:display-count="perPageCount"
			:deep-link="true"
			:filters="includeFirstOrderInBulkOrdersFilter">
			<div class="filter-bar mb-3">
				<div class="mb-2">
					<TextBoxFilter filter-id="search-term" placeholder="Search orders by no, name, or email" :resetText="false" />
				</div>

				<div>
					<DateRangeFilter filter-id="order-date-range" />
				</div>
			</div>

			<div class="d-flex mb-3">
				<div><PageSizeList :options="pageSizeOptions" /></div>
				<div class="flex-fill text-end"><PageSelector /></div>
			</div>

			<SearchGrid>
				<template v-slot:list="order">
					<OrderHistoryTile :order="order" />
				</template>

				<template #empty>
					<div>
						There is no order history currently available for your account. For more help please <router-link class="" to="/help/contact">Contact Us.</router-link>
					</div>
				</template>

				<template #loading-list>
					<MockSearchGrid>
						<MockProductListTile />
					</MockSearchGrid>
				</template>
			</SearchGrid>

			<div class="d-flex">
				<div class="flex-fill text-end"><PageSelector /></div>
			</div>
		</SearchContainer>
	</div>
</template>

<style lang="scss">
	.order-history-panel {
		.n-search-grid .tile {
			margin-bottom: 0.5rem;
			padding: 0.5rem;
		}

		.n-search-grid .tile:nth-child(odd) {
			background-color: #efefef;
		}
	}
</style>
