<script>

    // CartItemTile
    // A tile for an item in the cart.

    // Setup ---
    // Use the values below to modify how the tile appears
    const setup = {
        tileHeight: '8rem',           // Height of the full tab, fixed for alignment purposes
        productNameLines: 2            // Number of lines product name is allowed to wrap before being trimmed with ellipses
    };

    export const Setup = setup;

</script>

<script setup>

    // Imports ----
    import { reactive, defineProps, computed } from 'vue';
    import { store } from '@/Store';    
    import LimitMessages from '@/site/components/LimitMessages';
    import { UpdateCartItemQtyForm } from '@dd-nucleus/nucleus-vue';

    // Props ----
    const props = defineProps({
        // The cart item
        item: {
            type: Object,
            required: true
        },
        editable: {
            type: Boolean,
            required: false,
            default: true
        },
        removable: {
            type: Boolean,
            required: false,
            default: true
        }
    });

    // State ----
    const form = reactive(new UpdateCartItemQtyForm(props.item));

    const companionItems = computed(() => {
        let companionProducts = props.item.product.companionProducts;
        return companionProducts.map(({ companionProductNumber, companionProductThumbnailImageUrl, productId, companionProductId, companionProductName, quantity, isCalculated }) => ({
            itemNumber: companionProductNumber,
            thumbnailImageUrl: companionProductThumbnailImageUrl,
            parentProductId: productId,
            id: companionProductId,
            name: companionProductName,
            quantity: isCalculated ? props.item.quantity * quantity : quantity,
            isCalculated
        }));
    });

    // Handlers ----
    async function onRemoveFromCart() {
        await store.cart.removeItem(props.item.id);
    }

    const hideOutOfStockMessage = computed(() => {
        const filterOutOfStock = props.item.messages.filter(message => message.messageId.defaultLanguageText !== 'Out of stock');
        if (props.item.product.allowBackorders && props.item.product.quantityAvailable < 1) {
            return filterOutOfStock;
        } else {
            return props.item.messages;
        }
    });

    const validateCartQty = computed(() => {
        if (props.item.product.allowBackorders || props.item.product.limits.find(t => t.allowOrderBeyondLimit === true)) {
            return false;
        } else {
            return true;
        }
    })

</script>

<template>
    <div class="cart-item-tile" :style="{ minHeight: setup.tileHeight }">
        <div v-if="removable" class="float-end remove"><ActionButton icon="bi bi-x-lg" label="Remove" @click="onRemoveFromCart" /></div>
        <div class="inner-row d-flex flex-row">
            <div class="left">
                <ProductThumbnail :product="props.item.product" :height="setup.tileHeight" no-image-available-url="/images/no-image.png" />
            </div>
            <div class="middle flex-fill">
                <!-- Stretchy middle part-->
                <div class="tags mb-2">
                    <ProductTags :product="props.item.product" />
                </div>

                <!-- Product name and item number -->
                <router-link class="title-link" :to="`/product/${props.item.product.id}`">
                    <div class="name" v-snip="{ lines: setup.productNameLines }" :title="item.product.name">
                        {{ props.item.product.name }}
                    </div>
                </router-link>
                <div class="item-number"><router-link class="title-link" :to="`/product/${props.item.product.id}`">{{ props.item.product.itemNumber }}</router-link></div>

                <div class="companion-list" v-for="companion in companionItems" :key="companion.productId">
                    <CompanionItemTile class="companion" :companion="companion" :errorMessages="props.item.messages" />
                </div>
               
            </div>
            <!-- Fixed right panel -->
            <div class="right d-flex flex-column" :style="{ minHeight: setup.tileHeight }">
                <FormContainer :form="form">
                    <div class="flex-fill">
                        <CartItemQty :item="props.item" :editable="props.editable" :validate="validateCartQty" />
                        <FormMessages field-name="quantity" :add-box="false" class="mt-2" v-if="!props.item.product.allowBackorders" />
                        <FieldMessages field-name="quantity" class="mt-2" />
                        <LimitMessages :product="item.product" class="mt-2" />
                    </div>
                </FormContainer>
                <div class="messages">
                    <MessageList :messages="hideOutOfStockMessage" :add-box="false" />
                </div>
                <div class="favorite">
                    <ProductFavoriteIcon :product="props.item.product" label="Add as Favorite" selected-label="Favorite" />
                </div>
            </div>
        </div>
    </div>

</template>

<style lang="scss">

    .cart-item-tile {
        .inner-row {
            border-bottom: 1px solid $gray-20;
            padding-bottom: 1rem;
        }

        .left {
            width: 8rem;
        }

        .companion-list {
            display: flex;
            flex-direction: column;
        }

        .name {
            font-size: 1.0rem;
            line-height: 1.1rem;
        }

        a.title-link, a.title-link:visited, a.title-link:hover {
            color: var(--bs-body-color);
            text-decoration: none;
        }

        .item-number a.title-link {
            font-size: 0.7rem;
            color: $gray-50;
        }

        .n-product-add-to-cart-qty .text-box {
            border-color: #dddddd;
        }

        .btn[disabled] {
            opacity: 0.2;
        }

        .middle {
            padding-left: 1rem;
        }

        .favorite {
            margin-top: 1rem;
        }

        .right {
            width: 14rem;
        }

        .remove {
            margin-top: 0.5rem;
        }
    }

</style>