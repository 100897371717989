// registration.js
// Provides functionality for registering new users.
//
// Tracing ----
// Detailed tracing can be turned on by setting environment variable VUE_APP_TRACE_AADB2C to "true"

// Imports
import { useApi } from '@dd-nucleus/nucleus-vue';

const { POST } = useApi();

export default function () {
    // #region BEGIN Public Functions

    // Registers a new user account
    async function register(registrationDetail) {
        const url = '/api/site/register';

        trace('Attempting to register a new user', registrationDetail.email);

        const name = registrationDetail.firstName + ' ' + registrationDetail.lastName;

        const request = {
            registerUserAccount: {
                userName: registrationDetail.email,
                email: registrationDetail.email,
                password: registrationDetail.password,
                confirmPassword: registrationDetail.confirmPassword
            },
            createUserAccount: {
                contact: {
                    firstName: registrationDetail.firstName,
                    lastName: registrationDetail.lastName,
                    email: registrationDetail.email,
                    phoneNumber: registrationDetail.phoneNumber.replace(/\D/g,""),
                    companyName: registrationDetail.companyName
                },
                address: {
                    name: name,
                    addressee: name,
                    addressLine1: registrationDetail.addressLine1,
                    addressLine2: registrationDetail.addressLine2,
                    city: registrationDetail.city,
                    state: registrationDetail.state,
                    postalCode: registrationDetail.postalCode
                },
                userName: registrationDetail.email
            },
            registerStoreAccount: {
                automotivePartnerType: registrationDetail.automotiveType || '',
                title: registrationDetail.title
            }
        };

        // Post the request and inspect the response
        return await POST(url, request);
    }

    // #endregion

    // #region BEGIN Private Functions

    // Returns whether tracing is enabled
    function doTrace() {
        return (process.env.VUE_APP_TRACE_ASP_NET_AUTH === 'true');
    }

    // Traces a message, if enabled.
    function trace(message, ...args) {
        if (doTrace())
            console.log('[ASP.NET AUTH] ' + message, ...args);
    }

    // #endregion

    // Export Public Functions
    return {
        register
    }
}
