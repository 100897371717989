
// ShipToUserForm
// Implements the form that lets a user pick options for shipping to themselves.

// Imports ----
import { Form } from '@dd-nucleus/nucleus-vue';
import { store } from '@/Store';

export default class UpdateAccountInfoForm extends Form {

    // Constructor
    constructor() {
        // Pass our model to the base class
        super(
            {
                userName: '',
                email: ''
            });

        // Set required fields
        this.validator.fields.userName.isRequired = true;
        this.validator.fields.email.isRequired = true;

        // Force an immediate validation (e.g. for buttons disabled until valid)
        this.validate();

    }

    init() {
        this.model.userName = store.user.userName;
        this.model.email = store.user.email;
    }

    onValidate() {
        super.onValidate();

        //this.validator.fields.accountType.checkNotEmpty();
    }

    async onSubmit() {
        store.user.userName = this.model.userName;
        store.user.email = this.model.email;
        await store.account.updateInfo(this.model.userName, this.model.email);
    }
}