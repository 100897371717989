<script setup>
	// HomePage
	// Main home page

	// Components ----
	import ProductGridTile from './components/ProductGridTile';
	import MockProductGridTile from './components/MockProductGridTile';

	// Imports ----
	import { computed, reactive } from 'vue';
	import { store } from '@/Store';
	import { Util } from '@dd-nucleus/nucleus-vue';

	// Filter used for the featured products panel
	const featuredFilter = computed(() => {
		return store.products.filters.featured;
	});

	// Filter used for most ordered products
	const mostOrderedFilter = computed(() => {
		store.products.filters.mostOrdered.values = ["true", "5"];
		return store.products.filters.mostOrdered;
	})

	// Filter used to include only first order in bulkOrders in order search
	const includeFirstOrderInBulkOrdersFilter = {
		filterId: 'include-firstOrder-in-bulkOrders',
		values: ['']
	};

	let announcementToShow = reactive([]);

	store.onInitialized(async () => {
		if (store.user.userId) {
			const response = await store.announcement.getAnnouncementsForUser();
			if (response.succeeded) {
				announcementToShow.push(...response.announcements);
			} else {
				this.store.handleApiError(response);
				return null;
			}
		}
	});
</script>

<template>
	<PageTemplate>
		<div class="container root-page">
			<div class="row banner">
				<div class="col d-lg-none">
					<router-link to="/products"><img src="/images/home-page-banner.jpg" class="img-fluid" /></router-link>
				</div>
				<div class="col d-none d-lg-block">
					<router-link to="/products"><img src="/images/home-page-banner.jpg" class="img-fluid" /></router-link>
				</div>
			</div>

			<div class="row">
				<div class="col col-9">
					<div class="announcements" v-for="announcement in announcementToShow" :key="announcement.id">
						<h2 v-if="!!announcement.title">{{ announcement.title }}</h2>
						<p v-if="!!announcement.body" v-html="announcement.body"></p>
					</div>
					<BodyPanel class="home-body-panel">
						<template #title>
							FEATURED PRODUCTS
							<router-link to="/products">View All</router-link>
						</template>

						<SearchContainer search-id="products-for-user" sort-id="product-newest-to-oldest" model-id="tile" :display-count="12" :filters="featuredFilter">
							<SearchGrid>
								<template v-slot:grid="product">
									<ProductGridTile :product="product" />
								</template>

								<template #empty>
									<div>
										There are no featured products at the moment.
										<router-link to="/products">Click here</router-link> to search all products.
									</div>
								</template>

								<template #loading-grid>
									<MockSearchGrid>
										<MockProductGridTile />
									</MockSearchGrid>
								</template>
							</SearchGrid>
						</SearchContainer>
					</BodyPanel>
				</div>

				<div class="col col-3 side-bar">
					<SidePanel title="NEW MARKETING MATERIALS">
						<SearchContainer search-id="products-for-user" sort-id="product-itemNumber" model-id="row" :display-count="6" :filters="store.products.filters.new">
							<SearchResults>
								<template v-slot:result="product">
									<ProductLinkItem :product="product" />
								</template>

								<template #empty>
									No new marketing materials at the moment. Check back soon!
								</template>

								<template #loading>
									<div class="link-item">
										<span class="placeholder placeholder-wave w-50"></span>
									</div>
									<div class="link-item">
										<span class="placeholder placeholder-wave w-75"></span>
									</div>
									<div class="link-item">
										<span class="placeholder placeholder-wave w-50"></span>
									</div>
								</template>

								<ResultCountRangePanel :min-results="6">
									<div class="d-grid mt-3">
										<router-link to="/products" class="btn btn-color-1">SHOP ALL NEW ITEMS</router-link>
									</div>
								</ResultCountRangePanel>
							</SearchResults>
						</SearchContainer>
					</SidePanel>

					<SidePanel title="ORDER HISTORY">
						<SearchContainer search-id="orders-for-user"
							sort-id="order-newest-to-oldest"
							model-id="tile"
							:display-count="6"
							:filters="includeFirstOrderInBulkOrdersFilter">
							<SearchResults>
								<template v-slot:result="order">
									<div class="d-flex">
										<div class="flex-fill">
											<OrderLinkItem :order="order" />
										</div>
										<div class="text-end">
											{{ Util.date(order.orderDate) }}
										</div>
									</div>
								</template>

								<template #empty>
									You haven't placed any previous orders
								</template>

								<template #loading>
									<div class="link-item">
										<span class="placeholder placeholder-wave w-50"></span>
									</div>
									<div class="link-item">
										<span class="placeholder placeholder-wave w-75"></span>
									</div>
									<div class="link-item">
										<span class="placeholder placeholder-wave w-75"></span>
									</div>
								</template>
							</SearchResults>
						</SearchContainer>
					</SidePanel>

					<SidePanel title="MY FAVORITES">
						<SearchContainer search-id="products-for-user" sort-id="product-itemNumber" model-id="row" :display-count="6" :filters="store.products.filters.favorite">
							<SearchResults>
								<template v-slot:result="product">
									<ProductLinkItem :product="product" />
								</template>

								<template #empty>
									You haven't marked any products as favorites. Click the heart icon by a product to add one.
								</template>

								<template #loading>
									<div class="link-item">
										<span class="placeholder placeholder-wave w-75"></span>
									</div>
									<div class="link-item">
										<span class="placeholder placeholder-wave w-50"></span>
									</div>
									<div class="link-item">
										<span class="placeholder placeholder-wave w-50"></span>
									</div>
								</template>
							</SearchResults>

							<ResultCountRangePanel :min-results="3">
								<div class="d-grid mt-3">
									<router-link to="/account/favorites" class="btn btn-color-1">VIEW ALL MY FAVORITES</router-link>
								</div>
							</ResultCountRangePanel>
						</SearchContainer>
					</SidePanel>

					<!--to show top 5 products-->
					<SidePanel title="TOP 5 PRODUCTS">
						<SearchContainer search-id="products-for-user" sort-id="product-itemNumber" model-id="row" :display-count="5" :filters="mostOrderedFilter">
							<SearchResults>
								<template v-slot:result="product">
									<ProductLinkItem :product="product" />
								</template>

								<template #empty>
									Top 5 products not available
								</template>

								<template #loading>
									<div class="link-item">
										<span class="placeholder placeholder-wave w-75"></span>
									</div>
									<div class="link-item">
										<span class="placeholder placeholder-wave w-50"></span>
									</div>
									<div class="link-item">
										<span class="placeholder placeholder-wave w-50"></span>
									</div>
								</template>
							</SearchResults>
						</SearchContainer>
					</SidePanel>
				</div>
			</div>
		</div>
	</PageTemplate>
</template>

<style lang="scss">
	.banner {
		margin-bottom: 2rem;
		
		img {
			width: 100%;
		}
	}

	.announcements {
		margin: 10px 0;

		.announcement-title {
			background-color: #ffff40;
			color: #a6483b;
			margin: 0 0 5px 0;
			padding: 5px;
			width: fit-content;
		}

		.announcement-message {
			background-color: #ffc0c0;
			color: black;
			padding: 5px;
			border-radius: 2px;
			font-size: 14px;
			line-height: 20px;
			width: fit-content;

			p {
				margin: 0 !important;
			}
		}
	}

	.side-bar {
		.n-side-panel {
			margin-bottom: 2rem;
		}
	}

	.link-item {
		color: $color-1;
		opacity: 0.3;
		margin-bottom: 0.25rem;
	}

	.home-body-panel {
		.title {
			font-size: 0.9rem !important;
			border-bottom: 1px solid $gray-10 !important;
			font-weight: $bold !important;
			color: $gray-50 !important;
			background-color: $white !important;
			padding-left: 0rem !important;
			padding-bottom: 0.5rem !important;
			padding-top: 0rem !important;
		}
	}

	.home-body-panel .body {
		padding-top: 1rem !important;
		padding-left: 0rem !important;
		padding-right: 0rem !important;
	}

	.n-side-panel .body {
		min-height: 8rem;
		vertical-align: middle;
	}
</style>
