<script setup>

    // ShippingAddressTile
    // Customizes the standard AddressTile component with options/markup specific to user shipping addresses.

    // Components ----

    // Imports ----
    import { defineProps, ref, computed } from 'vue';
    import { ShippingAddressForm } from '@dd-nucleus/nucleus-vue';
    import { store, DEFAULT_COUNTRY_CODE } from '@/Store';

    // Props ----
    const props = defineProps({
        // The address to show
        address: {
            type: Object,
            required: true
        },
        // An optional height to enforce.
        height: {
            type: String,
            required: false,
            default: null
        },
        // Whether to add a "set default" action button
        canSetDefault: {
            type: Boolean,
            required: false,
            default: false
        },
        // Whether to show a border around the tile.
        border: {
            type: Boolean,
            required: false,
            default: true
        },
        // An optional height to enforce for the address block.
        addressHeight: {
            type: String,
            required: false,
            default: null
        },
        editable: {
            type: Boolean,
            required: false,
            default: false,
        },
        deletable: {
            type: Boolean,
            required: false,
            default: false,
        }
    });

    // State ----
    const selectedCountry = ref(DEFAULT_COUNTRY_CODE);
    let shippingAddressForm = ref(new ShippingAddressForm({...props.address}));
    let editing = ref(false);
    let messages = ref([]);

    // Computed ----
    const isDefault = computed(() => {
        if (typeof props.address === 'undefined')
            return false;
        else
            return (store.shipping.addresses.length > 0 && props.address.id === store.shipping.addresses[0].id);
    });

    const isDeletable = computed(() => {
        return props.deletable && !isDefault.value;
    });

    const isEditable = computed(() => {
        // TODO: make the backend send a flag called as `editable` for each address
        // which will determine if the address is editable or not.
        return props.editable; // && props.address.editable;
    })

    // Events ----

    // Handlers ----
    async function onSetDefault() {
        await store.shipping.setDefaultShippingAddress(props.address.id);
    }

    async function onEdit() {
        shippingAddressForm.value = new ShippingAddressForm({...props.address});
        selectedCountry.value = shippingAddressForm.value.model.countryCode;
        editing.value = true;
    }

    async function onDelete() {
        const confirmed = window.confirm('Are you sure you want to delete this address?');
        if (confirmed) {
            const response = await store.shipping.deleteAddress(props.address.id);
            if (response.succeeded) {
                store.toast.success('Shipping address deleted.');
                await store.refreshSearches('addresses-for-user');
            } else {
                store.toast.error('Failed to delete shipping address.');
                messages.value = response.messages || [];
            }
        }
    }

    function onCancelEditShippingAddress() {
        editing.value = false;
    }

    async function onSubmitEditShippingAddress() {
        const success = await shippingAddressForm.value.submit();
        if (success) {
            editing.value = false;
            store.toast.success('Shipping address updated.');
            await store.refreshSearches('addresses-for-user');
        } else {
            store.toast.error('Failed to update shipping address.');
        }
    }

    function onChanged(form) {
        if (selectedCountry.value != form.model.countryCode) {
            selectedCountry.value = form.model.countryCode;
            form.model.state = '';
            form.model.province = '';
            form.model.addressLine1 = '';
            form.model.addressLine2 = '';
            form.model.city = '';
            form.model.postalCode = '';
        }
    }
</script>

<template>

    <div class="shipping-address-tile">
        <MessageList :messages="messages" />
        <div v-if="editing" class="subform mt-2">
            <div class="col-12 col-md-8">
                <EditShippingAddress v-if="editing" :form="shippingAddressForm" :address-only="true" :start-address-lines="2" :max-address-lines="2" :selectedCountry="selectedCountry" @change="onChanged">
                    <div class="row">
                        <div class="col-12 pt-2">
                            <button class="btn btn-outline-color-2 me-2" @click="onCancelEditShippingAddress">Cancel</button>
                            <button class="btn btn-color-2" @click="onSubmitEditShippingAddress" :disabled="shippingAddressForm.status.isInvalid">Save</button>
                        </div>
                    </div>
                </EditShippingAddress>
            </div>
        </div>

        <AddressTile v-else :deletable="isDeletable" :editable="isEditable" :address="props.address" :height="props.height" :address-height="props.addressHeight" :border="props.border" @delete="onDelete" @edit="onEdit">
            <template #top>
            </template>

            <div class="mt-2">
                <div class="tags">
                    <span v-if="isDefault" class="badge default-shipping"><i class="bi bi-star-fill"></i> Default Shipping</span>
                </div>
                <ActionButton v-if="props.canSetDefault && !isDefault" icon="bi bi-star" label="Set Default" @click="onSetDefault" />
            </div>
        </AddressTile>
    </div>

</template>

<style lang="scss">

    .shipping-address-tile {
        overflow-x: clip;

        .n-action-button {
            margin-right: 0.5rem;
        }

        .default-shipping {
            background-color: $highlight-1;         // TODO: SCSS variable
        }
    }

</style>