<script setup>

    // ShipTo
    // Allows the user to select from different options for specifying ship-to address(es).

    // Constants ----
    const ShipToTypes = {
        TO_USER: 'TO_USER',             // Shipping to the current user
        TO_CONTACT: 'TO_CONTACT',       // Shipping to a single contact
        TO_CONTACTS: 'TO_CONTACTS'      // Shipping to multiple contacts
    };

    // Components ----
    import ShipToUser from './ShipToUser';
    // import ShipToContact from './ShipToContact';
    // import ShipToContacts from './ShipToContacts';
    // import ShipToList from './ShipToList';
    import AddressReviewTable from './AddressReviewTable';
    import OrderContactTile from '@/site/components/OrderContactTile';
    // import { Util } from '@dd-nucleus/nucleus-vue';


    // Imports ----
    import { ref, reactive, defineProps, computed, onMounted } from 'vue';
    import { store } from '@/Store';

    // Props ----
    const props = defineProps({
        // Whether this ship-to is editable
        editable: {
            type: Boolean,
            required: false,
            default: true
        },
        // Whether to start in editing mode
        editing: {
            type: Boolean,
            required: false,
            default: false
        }
    });

    // State ----
    const editing = ref(props.editing);
    const isLoaded = ref(false);

    const shipToTypes = reactive([
        { id: ShipToTypes.TO_USER, text: 'Ship to My Address', active: true },
        // { id: ShipToTypes.TO_CONTACT, text: 'Ship to a Contact' }
    ]);

    const rolesForMultiShipTo = [
        'Client.Admin',
        'Qualfon.CsdAdmin',
        'Qualfon.Support',
        'SA'
    ];

    /*        { id: ShipToTypes.TO_CONTACTS, text: 'Ship to a Contacts' }*/

    // Computed ----
    const shipToType = computed(() => {
        switch (store.cart.current.shipToMode) {
            case store.cart.ShipToMode.ShipToUser:
            case store.cart.ShipToMode.DistributionList:
                return ShipToTypes.TO_USER;
            // case store.cart.ShipToMode.ShipToContact:
            //     return ShipToTypes.TO_CONTACT;
            // case store.cart.ShipToMode.ShipToContacts:
            //     return ShipToTypes.TO_CONTACTS;
            default:
                if (typeof store.cart.current.shipToMode !== 'undefined')
                    console.error(`ERROR: Unexpected ship to mode '${store.cart.current.shipToMode}' encountered`);
                return ShipToTypes.TO_USER;
        }
    });

    const shipToTypeTitle = computed(() => {
        switch (shipToType.value) {
            case ShipToTypes.TO_USER:
                return "Ship to This Address:";
            // case ShipToTypes.TO_CONTACT:
            //     return "Ship to This Contact:";
            // case ShipToTypes.TO_CONTACTS:
            //     return "Ship to Multiple Contacts:";
        }

        return null;
    });

    // Handlers ----
    onMounted(() => {
        update();
    });

    store.cart.onCartLoaded(() => {
        update();
    });

    store.cart.onCartUpdated(() => {
        update();
    });

    // Called once we know we have a cart to work with
    function update() {
        if (typeof store.cart.current.shipToList === 'undefined') return;

        // TODO: Figure out how this will be checked
        // let canMultiShip = false;
        // canMultiShip = hasAccessToMultiShip();
        // if (canMultiShip) {
        //     // update the text for ship to contact to be plural
        //     shipToTypes.find((item) => item.id === ShipToTypes.TO_CONTACT).text = 'Ship to Contacts';

        //     // add an option to ship to contact list
        //     if (Util.isUndefined(shipToTypes.find((item) => item.id === ShipToTypes.TO_CONTACTS)))
        //         shipToTypes.push({ id: ShipToTypes.TO_CONTACTS, text: 'Ship to Contact List' });
        // }

        isLoaded.value = true;
    }

    function onChange() {
        editing.value = !editing.value;
    }

    function hasAccessToMultiShip() {

        // check if user has access to multiship
        const userRoles = store.user.roles.map(r => r.id);
        for (let i = 0; i < rolesForMultiShipTo.length; i++) {
            if (userRoles.includes(rolesForMultiShipTo[i])) {
                return true;
            }
        }
        return false;
    }

    function setShipToMode(id) {
        switch (id) {
            case ShipToTypes.TO_USER:
                return store.cart.setShipToModeToShipUser();
            case ShipToTypes.TO_CONTACT:
                if (hasAccessToMultiShip()) {
                    return store.cart.setShipToModeToMultipleContacts();
                } else {
                    return store.cart.setShipToModeToSingleContact();
                }
            case ShipToTypes.TO_CONTACTS:
                return store.cart.setShipToModeToMultipleContacts();
            default:
                return false;
        }
    }

</script>

<template>

    <div class="ship-to">
        <template v-if="editing">

            <ul class="nav nav-tabs">
                <li v-for="type in shipToTypes" :key="type.id" class="nav-item" @click="setShipToMode(type.id)">
                    <button :id="type.id" class="nav-link" :class="{ 'active': type.active }" data-bs-toggle="tab" :data-bs-target="'#' + type.id + '-pane'" type="button" role="tab">{{ type.text }}</button>
                </li>
            </ul>

            <div class="tab-content">
                <div class="tab-pane fade pt-3 active show" id="TO_USER-pane" role="tabpanel" aria-labelledby="TO_USER" tabindex="0">
                    <ShipToUser :can-edit="true" />
                </div>

                <!-- <div class="tab-pane fade pt-3" id="TO_CONTACT-pane" role="tabpanel" aria-labelledby="TO_CONTACT" tabindex="0">
                    <ShipToContacts v-if="hasAccessToMultiShip()" :can-edit="true" />
                    <ShipToContact v-else :can-edit="true" />
                </div>

                <ShowOnlyForRoles :roles="rolesForMultiShipTo">
                    <div class="tab-pane fade pt-3" id="TO_CONTACTS-pane" role="tabpanel" aria-labelledby="TO_CONTACTS" tabindex="0">
                        <ShipToList :can-edit="true" />
                    </div>
                </ShowOnlyForRoles> -->


            </div>

        </template>

        <div v-else class="summary">
            <div class="ship-type-title mb-2">{{ shipToTypeTitle }}</div>

            <OrderContactTile v-if="isLoaded && shipToType !== ShipToTypes.TO_CONTACTS " :contact="store.cart.current.shipToList[0]" :border="false">
            </OrderContactTile>

            <AddressReviewTable v-if="isLoaded && shipToType === ShipToTypes.TO_CONTACTS" readonly>
            </AddressReviewTable>

            <ActionButton v-if="props.editable" icon="bi bi-arrow-left-right" label="Change" @click="onChange" />
        </div>
    </div>

</template>

<style lang="scss">

    .ship-to {
        .ship-type-title {
            font-size: 0.9rem;
            font-weight: $bold;
        }
    }
</style>