
// AccountTypeForm
// Implements the form that lets a user pick an account type.

// Imports ----
import { Form } from '@dd-nucleus/nucleus-vue';

export default class AccountTypeForm extends Form {

    // Constructor
    constructor() {
        // Pass our model to the base class
        super(
          {
            accountType: ''
          });
    }

    onValidate() {
        super.onValidate();

        this.validator.fields.accountType.checkNotEmpty();
    }
}