// ResetPasswordForm
// Implements the form that allows a user to reset their password.

// Imports ----
import { MessageType, Form } from '@dd-nucleus/nucleus-vue';
import { store } from '@/Store';

export default class SetPasswordForm extends Form {

    // Constructor
    constructor() {
        // Pass our model to the base class.
        super({
            linkId: '',
            userName: '',
            email: '',
            password: '',
            confirmPassword: ''
        });
    }

    onValidate() {
        super.onValidate();

        this.validator.fields.password.checkNotEmpty();
        this.validator.fields.confirmPassword.checkNotEmpty();
    }

    async onValidateAsync() {
        if (this.model.password && this.model.password.length >0) {
            const response = await store.authenticator.validatePassword(this.model.userName, this.model.email, this.model.password, this.model.confirmPassword);

            this.validator.fields.password.reset();

            response.messages.forEach(m => {
                if (m.messageType === MessageType.OK) {
                    this.validator.fields.password.addOk(m.userMessage);
                }
                else {
                    this.validator.fields.password.addError(m.userMessage);
                }
            });
        }
    }

    async onSubmit() {
        if (!await store.authenticator.setPasswordFromLink(this.model.linkId, this.model.password)) {
            this.validator.addError('The set password request could not be completed.');
        }
    }
}
