
// SignUpForm
// Implements the form to create an account for a user. The constructor accepts a model from a subclass, so it can provide the core fields and validations needed,
// while allowing the subclass to add its own additional fields and validations.

// Imports ----
import { MessageType, Form } from '@dd-nucleus/nucleus-vue';
import { store } from '@/Store';
import useRegistration from '@/services/registration';

const { register } = useRegistration();


export default class SignUpForm extends Form {

    // Constructor
    constructor(model = null) {
        // Merge in the supplied model (if any) and pass to the base class
        super(
            Object.assign(model == null ? {} : model,
                {
                    companyName: '',
                    firstName: '',
                    lastName: '',
                    phoneNumber: '',
                    email: '',
                    title: '',
                    addressLine1: '',
                    addressLine2: '',
                    city: '',
                    state: '',
                    postalCode: '',
                    password: '',
                    confirmPassword: ''
                }));

        this.validator.fields.email.isEmail = true;

        // Set required fields
        this.validator.fields.email.isRequired = true;
        this.validator.fields.password.isRequired = true;
        this.validator.fields.confirmPassword.isRequired = true;
        this.validator.fields.companyName.isRequired = true;
        this.validator.fields.firstName.isRequired = true;
        this.validator.fields.lastName.isRequired = true;
        this.validator.fields.phoneNumber.isRequired = true;
        this.validator.fields.title.isRequired = true;
        this.validator.fields.addressLine1.isRequired = true;
        this.validator.fields.city.isRequired = true;
        this.validator.fields.state.isRequired = true;
        this.validator.fields.postalCode.isRequired = true;

        // Set optional fields
        this.validator.fields.addressLine2.isRequired = false;

        // Set maximum lengths
        // TODO: Expose these numbers from the backend somehow
        this.validator.fields.email.maxLength = 50;
        this.validator.fields.companyName.maxLength = 100;
        this.validator.fields.firstName.maxLength = 50;
        this.validator.fields.lastName.maxLength = 50;
        this.validator.fields.phoneNumber.maxLength = 14;
        this.validator.fields.title.maxLength = 100;
        this.validator.fields.addressLine1.maxLength = 100;
        this.validator.fields.addressLine2.maxLength = 100;
        this.validator.fields.city.maxLength = 50;
        this.validator.fields.state.maxLength = 2;
        this.validator.fields.postalCode.maxLength = 20;

        // Set minimum lengths
        this.validator.fields.phoneNumber.minLength = 14;

        // No PO boxes
        this.validator.fields.addressLine1.noPoBox = true;

        // Force an immediate validation (e.g. for buttons disabled until valid)
        this.validate();
    }

    onValidate() {
        super.onValidate();
    }

    async onValidateAsync() {
        super.onValidateAsync();

        if (this.model.password && this.model.password.length > 0) {
            const response = await store.authenticator.validatePassword(this.model.email, this.model.email, this.model.password, this.model.confirmPassword);

            this.validator.fields.password.reset();

            let isValid = true;

            response.messages.forEach(m => {
                if (m.messageType === MessageType.OK) {
                    this.validator.fields.password.addOk(m.userMessage);
                }
                else {
                    this.validator.fields.password.addError(m.userMessage);
                    isValid = false;
                }
            });

            if (!isValid) {
                this.validator.addError('Password requirements not met');
            }
        }
    }

    async onSubmit() {
        const response = await register(this.model);

        if (response.succeeded)
            return true;
        else {
            // Add the messages from the response into the validator
            this.validator.addMessages(response.messages);
            return false;
        }
    }

    scrollTop() {
        window.scrollTo(0, 0);
    }
}
